<template>
  <div class="logo-container">
    <img src="@/assets/logo.png" alt="" class="logo">
  </div>
  <h1 class="title">Lorem ipsum dolor sit amet et delectus accommodare his consul copiosae legendos.</h1>
  <div class="cont-container">
    <div class="content-container">
      <div class="content-items">
        <p>
          Lorem ipsum dolor sit amet et delectus accommodare his consul copiosae legendos at vix ad putent delectus. Vidit dissentiet eos cu eum an brute copiosae hendrerit. Eos erant dolorum an. Per facer affert ut. Mei iisque mentitum
        </p>
        <input @change="previewImage" ref="file_input" class="image-selector"  type="file" name="image" placeholder="Select File">
        <button @click="uploadImage" class="upload-btn" :disabled="!img_data || uploading">{{(uploading) ? 'Processing':'Process'}}</button>
      </div>
      <div class="content-items">
        <div class="image-container">
          <img class="image-preview" :src="img_data" alt="">
        </div>
      </div>
    </div>
  </div>

  <Result v-if="rslt_img" :img_url="rslt_img" :colors="colors"/>
</template>

<script>
// @ is an alias to /src
import {ref} from 'vue'
import axios from 'axios'
import Result from '@/components/Result'

export default {
  name: 'Home',
  components: {
    Result
  },
  setup(){
    const img_data = ref(false)
    const file_input = ref(false)
    const rslt_img = ref('')
    const colors = ref('')
    const uploading = ref(false)

    const uploadImage = () => {
      if (img_data.value){
        uploading.value = true
        let fd = new FormData()
        fd.append("image",file_input.value.files[0])

        axios.post('http://lightofficer.com/test/predict',fd,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          rslt_img.value = 'http://lightofficer.com/test/'+res.data.data
          colors.value = res.data.colors

          file_input.value.value = ''
          uploading.value = false
        })

        // console.log(file_input.value)
      }
    }

    const previewImage = (e) => {
      // console.log('hello',e.target.files[0])
      img_data.value = false
      if (e.target.files.length > 0){
        rslt_img.value = ''
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
          // console.log(reader.result.toString().substr(reader.result.toString().indexOf(',') + 1))
          img_data.value = reader.result.toString()
          // console.log(reader.result.toString())
        }        
      }
    }

    return {
      img_data,file_input,rslt_img,colors,uploading,
      previewImage,uploadImage
    };
  }
}
</script>


<style>
  *{
    font-family:Arial, Helvetica, sans-serif;
  }
  .title {
    text-align: center;
    font-size: 16px;
  }

  .logo-container {
    text-align: center;
  }
  .logo{
    width: 100px;
  }
  .content-items {
    text-align: center;
    padding: 8px;
  }
  .content-items p{
    text-align: justify;
  }
  .cont-container{
    text-align: center;
  }
  .image-selector{
    display: block;
    border: 1px solid;
    padding: 8px;
  }
  .upload-btn{
    display: block;
    padding: 5px 25px;
    margin-top: 10px;
    background: white;
    border: 1px solid;
    cursor: pointer;
  }

  .upload-btn:hover{
    background: black;
    color: white;
  }

  .upload-btn[disabled]:hover{
    background:white;
    color: gray;
  }

  .image-preview{
    width: 95%;
    height: 300px;
    background: gray;
  }
  .content-container{
    border: 1px solid;
  }
  /* .content-items.first{
    border-bottom: 0px solid;
  } */
  .content-items{
    border: 1px solid;
    padding: 20px;
  }

  @media screen and (min-width: 860px){
    .content-container {
      display: inline-flex;
      margin: 0 auto;
    }
    .content-items{
      width: 375px;
    }
  }

</style>