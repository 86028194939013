<template>
  <div class="result">
    <h2>Result</h2>
    <div class="cont-container">
        <div class="content-container">
            <div class="content-items">
                <img class="result-img" :src="img_url" alt="" srcset="">
            </div>
            <div class="content-items result-container">
                <h3>Color Similarity:</h3>
                <template v-for="color in Object.keys(colors)" :key="color">
                    <span><b>{{color}}: </b> {{colors[color].toFixed(4)}}%</span>
                </template>
                <!-- <span><b>Red: </b> 75%</span>
                <span><b>Black: </b>81%</span> -->
            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import { onMounted } from 'vue'
    export default {
        props: ['img_url','colors'],
        setup(props){
            onMounted(() => {
                console.log(props.colors)
            })

        }
    }
</script>

<style>
    .result{
        text-align: center;
    }    
    .result-img {
        width: 300px;
    }
    .result-container{
        text-align: left;
        padding: 10px;
    }
    .result-container span{
        display: block;
    }
</style>